import NextImage from 'next/image';

import type { TypeImageComplete } from '@/types';
import type { FunctionComponent } from 'react';

export const Image: FunctionComponent<TypeImageComplete> = ({
  src,
  alt,
  width,
  height,
}) => (
  <div className={`relative mb-6 ${width} ${height}`}>
    <NextImage src={src} alt={alt ?? ''} fill className="relative" />
  </div>
);
