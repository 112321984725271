import NextLink from 'next/link';
import { oneLine } from 'common-tags';

import { Button, Link, Text } from '@/atoms';
import { ButtonSize, ButtonVariant } from '@/atoms/button/types';
import { Gallery, Prose, TwoColumn } from '@/molecules';

import { toArray } from '@/utils';

import type { FunctionComponent } from 'react';
import type { TypeContentImageProps } from './types';

export const ContentImage: FunctionComponent<TypeContentImageProps> = ({
  className = '',
  reverse = false,
  image,
  description,
  font = 'large',
  title,
  link,
  button,
  isViewForms,
  flat,
}: TypeContentImageProps) => {
  const imagesToShow = toArray(image);

  return (
    <TwoColumn
      reverse={reverse}
      gap="gap-8"
      className={oneLine`
        ${reverse ? '!flex flex-col-reverse lg:flex-row' : ''}
        ${className}
      `}
    >
      <TwoColumn.Left>
        <Gallery
          isGrid={false}
          images={imagesToShow}
          typeModal={isViewForms ? 'form' : 'grid'}
          {...(flat && { flat: flat })}
        />
      </TwoColumn.Left>

      <TwoColumn.Right className="flex flex-col items-start justify-center">
        {title && (
          <Text className="text-headline text-headline--h3 mb-4">{title}</Text>
        )}

        {description && (
          <Prose
            className={oneLine`
              text-body 
              ${font === 'small' ? '[&_p]:text-body--s' : '[&_p]:text-body--l'}
              ${font === 'medium' ? '[&_p]:text-body--m' : '[&_p]:text-body--l'}
            `}
            html={description}
          />
        )}

        {link && (
          <NextLink href={link.href} prefetch={false} passHref legacyBehavior>
            {link.type === 'link' ? (
              <Link icon="right" className="mt-8 font-bold md:mt-14">
                {link.label}
              </Link>
            ) : (
              <Button
                size={ButtonSize.SMALL}
                variant={ButtonVariant.PRIMARY}
                as="a"
                className="mt-14"
              >
                {link.label}
              </Button>
            )}
          </NextLink>
        )}

        {button && (
          <Button
            size={ButtonSize.SMALL}
            variant={ButtonVariant.PRIMARY}
            className="mt-14"
            onClick={button.onClick}
          >
            {button.label}
          </Button>
        )}
      </TwoColumn.Right>
    </TwoColumn>
  );
};

ContentImage.displayName = 'ContentImage';
