import { oneLine } from 'common-tags';

import { IconTextVariant } from './types';

import type { FunctionComponent } from 'react';
import type { TypeIconTextProps } from './types';

const VARIANT_MAPS: Record<IconTextVariant, string> = {
  [IconTextVariant.SMALL]: '',
  [IconTextVariant.MEDIUM]: '[&_span]:text-body--m',
};

/**
 * IconText
 */
export const IconText: FunctionComponent<TypeIconTextProps> = ({
  className = '',
  title,
  value,
  Icon,
  variant,
}: TypeIconTextProps) => (
  <div
    className={oneLine`
      flex items-center
      ${variant === IconTextVariant.SMALL ? 'gap-x-1' : 'gap-x-2'}
      ${className}
    `}
  >
    <Icon width={24} className="flex-shrink-0" />

    <div
      className={`text-body text-body--s [&_p]:text-neutrals-500 [&_p+span]:font-bold ${VARIANT_MAPS[variant]}`}
    >
      {title && <p>{title}</p>}
      <span>{value}</span>
    </div>
  </div>
);

IconText.displayName = 'IconText';
