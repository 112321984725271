import Bathtub from '@/icons/light/bathtub.svg';
import Bedroom from '@/icons/light/bedroom.svg';
import Garaje from '@/icons/light/garaje.svg';
import Mueble from '@/icons/light/mueble.svg';
import Otros from '@/icons/light/otros.svg';
import Piscina from '@/icons/light/piscina.svg';
import Sun from '@/icons/light/sun.svg';

import Generic from '@/assets/images/generic.jpg';
import { flatName, flatPath } from '@/components/algolia/utils';
import { formatNumberToCurrency } from '@/utils';

import type { TypeFlatAnalytics } from '@/types';

export const cardTransformer = (hit: any, onClick: () => void) => {
  const isForSale = hit?.ventaAlquiler?.toLowerCase() === 'venta';

  return {
    image: {
      alt: `${hit?.tipoDeUsoDescription} en ${hit?.ciudad}`,
      src: hit?.fotos?.[0]?.url || Generic.src,
    },
    title: flatName(hit),
    price: formatNumberToCurrency(
      isForSale
        ? (hit?.importeVenta as number)
        : (hit?.importeRecibofinal as number)
    ),
    period: isForSale ? '' : '/mes',
    tag: '¡Nuevo!',
    description:
      hit?.ventaAlquiler === 'MED_EST'
        ? ['Consultar disponibilidad']
        : [
            ...(hit?.metrosComputablesUA
              ? [`${hit.metrosComputablesUA} m2`]
              : []),
            ...(hit?.hasGaraje !== 'No' && hit?.hasTraseros !== 'No'
              ? ['Garaje y Trastero incluidos']
              : []),
          ],
    features: [
      ...(hit?.habitaciones
        ? [{ Icon: Bedroom, value: `${hit.habitaciones} Habs.` }]
        : []),
      ...(hit?.banos ? [{ Icon: Bathtub, value: `${hit.banos} baños` }] : []),
      ...(hit?.hasAmueblado !== 'No'
        ? [
            {
              Icon: Mueble,
              value: 'Amueb.',
            },
          ]
        : []),
      ...(hit?.hasPiscina !== 'No'
        ? [
            {
              Icon: Piscina,
              value: 'Piscina',
            },
          ]
        : []),
      ...(hit?.hasGaraje !== 'No'
        ? [
            {
              Icon: Garaje,
              value: 'Garaje',
            },
          ]
        : []),
      ...(hit?.hasTerrazas !== 'No'
        ? [
            {
              Icon: Sun,
              value: 'Terraza',
            },
          ]
        : []),
      ...(hit?.hasTraseros !== 'No'
        ? [
            {
              Icon: Otros,
              value: 'Trastero',
            },
          ]
        : []),
    ],
    button: {
      label: 'Contactar',
      onClick: onClick,
    },
    href: flatPath(hit),
    flat: {
      codigoUnidadAlquiler: hit?.codigoUnidadAlquiler as string,
      reservable: hit?.reservable as string,
      visitable: hit?.visitable as string,
      nombreUE: hit?.nombreUE as string,
      ventaAlquiler: hit?.ventaAlquiler as string,
      name: flatName(hit),
    } as TypeFlatAnalytics,
  };
};
