import type { FunctionComponent } from 'react';
import type { TypeColumn } from './types';

export const Column: FunctionComponent<TypeColumn> = ({
  as: HtmlTag = 'div',
  className,
  children,
}: TypeColumn) => (
  <HtmlTag className={`w-full ${className}`}>{children}</HtmlTag>
);
