import { Container } from '@/atoms';
import { TabGroup } from '@/molecules';

/**
 * ComponentRevisadasTabs
 */
export const ComponentRevisadasTabs = ({ Link }: any) =>
  Link?.length > 0 ? (
    <Container wrapper>
      <TabGroup
        tabsgroups={Link.map((itm: any) => ({
          href: itm.Link,
          label: itm.Titulo,
        }))}
      />
    </Container>
  ) : null;

ComponentRevisadasTabs.displayName = 'ComponentRevisadasTabs';
