import NextLink from 'next/link';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { oneLine } from 'common-tags';

import ChevronLeft from '@/icons/light/chevron-left.svg';
import ChevronRight from '@/icons/light/chevron-right.svg';
import Link from '@/atoms/link';

import { toArray } from '@/lib/utils';

import type { Options, Splide as TypeSplide } from '@splidejs/splide';
import type { FunctionComponent, ReactNode } from 'react';
import type { TypeSliderCardProps } from '.';
import '@splidejs/react-splide/css/core';

const ARROWS =
  'rounded-full bg-neutrals-0 hover:bg-primary-100 text-primary-500 p-2 disabled:opacity-20 disabled:pointer-events-none';

export const SliderCard: FunctionComponent<TypeSliderCardProps> = ({
  className = '',
  link,
  breakpoints,
  perPage,
  children,
  height,
  type,
  gap = 24,
}: TypeSliderCardProps) => {
  const childrenArray = toArray(children);
  const DEFUALT_PADDING = '15px';
  const adjustPadding = (
    splide: TypeSplide,
    track: HTMLElement,
    rootParent: HTMLElement | null,
    start: number,
    last: number,
    padding: Options['padding']
  ) => {
    if (padding) {
      if (start === splide.index) {
        track.style.paddingLeft = DEFUALT_PADDING;
      } else {
        track.style.paddingLeft = `${padding}px`;
      }

      if (last === splide.index) {
        track.style.paddingRight = DEFUALT_PADDING;
      } else {
        track.style.paddingRight = `${padding}px`;
      }
    } else {
      track.style.paddingLeft = '0';
      track.style.paddingRight = '0';
    }
  };
  const adjustAllPaddings = (splide: TypeSplide) => {
    const last = splide.Components.Controller.getEnd();
    const start = 0;
    const padding = splide.options.padding;
    const track = splide.Components.Elements.track;
    const root = splide.Components.Elements.root;
    const rootParent = root.parentElement;
    const breakpoints = splide.options.breakpoints;

    if (breakpoints) {
      Object.entries(breakpoints).forEach(([key, value], index) => {
        if (
          window.screen.width <= parseInt(key) &&
          ((index > 0 &&
            window.screen.width >
              parseInt(Object.keys(breakpoints)[index - 1])) ||
            index === 0)
        ) {
          adjustPadding(splide, track, rootParent, start, last, value.padding);
        }
      });
    }

    adjustPadding(splide, track, rootParent, start, last, padding);
  };

  const options: Options = {
    ...(type && { type }),
    perPage: perPage,
    ...(breakpoints && { breakpoints }),
    ...(height && { height }),
    ...(gap && { gap }),
    perMove: 1,
    pagination: false,
    arrows: true,
    lazyLoad: true,
    classes: {
      prev: 'rotate-180',
    },
  };

  /**
   * Render slides.
   *
   * @return Slide nodes.
   */
  const renderSlides = (): ReactNode => (
    <SplideTrack className="py-3.5 md:!-mx-3.5 md:!p-3.5">
      {childrenArray.map((child, index) => (
        <SplideSlide key={index}>{child}</SplideSlide>
      ))}
    </SplideTrack>
  );

  return (
    <Splide
      options={options}
      className={`group -mx-4 flex flex-col gap-2 md:mx-0 ${className}`}
      onMove={(splide) => adjustAllPaddings(splide)}
      onMounted={(splide) => adjustAllPaddings(splide)}
      hasTrack={false}
    >
      {renderSlides()}

      <div
        className={oneLine`
          flex items-center
          ${link ? 'justify-between' : 'justify-end'}
        `}
      >
        {link && (
          <div className="mx-4 md:mx-0">
            <NextLink href={link.href} passHref prefetch={false} legacyBehavior>
              <Link icon="right">{link.label}</Link>
            </NextLink>
          </div>
        )}

        <div className="splide__arrows mx-4 flex w-max flex-row justify-end gap-3 self-end group-[:not(.is-overflow)]:hidden md:mx-0">
          <button
            className={`splide__arrows splide__arrow--prev ${ARROWS}`}
            type="button"
            role="tab"
            aria-label="Anterior slide"
          >
            <ChevronLeft width={24} height={24} />
          </button>

          <button
            className={`splide__arrows splide__arrow--next ${ARROWS}`}
            type="button"
            role="tab"
            aria-label="Siguiente slide"
          >
            <ChevronRight width={24} height={24} />
          </button>
        </div>
      </div>
    </Splide>
  );
};

SliderCard.displayName = 'SliderCard';
