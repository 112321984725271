import Image from 'next/image';
import Link from 'next/link';

import type { FunctionComponent } from 'react';
import type { TypeCardZoneProps } from './types';

/**
 * CardZone
 */
export const CardZone: FunctionComponent<TypeCardZoneProps> = ({
  href,
  image,
  title,
  className,
}: TypeCardZoneProps) => (
  <Link href={href} prefetch={false} legacyBehavior>
    <a className={`flex flex-col gap-2 ${className}`}>
      <div className="relative h-[220px] w-full">
        <Image
          alt={image.alt ?? title}
          src={image.src}
          fill
          sizes="(max-width: 768px) 100vw,
          (max-width: 1200px) 50vw,
          33vw"
          className="rounded object-cover"
          draggable={false}
        />
      </div>

      <p className="text-body text-body--m font-bold">{title}</p>
    </a>
  </Link>
);

CardZone.displayName = 'CardZone';
