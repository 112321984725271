import Link from 'next/link';

import Building from '@/icons/duotone/building.svg';
import { Button, Container } from '@/atoms';
import { FeaturedText } from '@/molecules';

/**
 * ComponentRevisadasCtoWithBanner
 */
export const ComponentRevisadasCtoWithBanner = ({
  Desciption,
  CtaBoton,
}: any) => (
  <Container wrapper>
    <FeaturedText Icon={Building} text={Desciption}>
      <Link href={CtaBoton.Link} prefetch={false} passHref legacyBehavior>
        <Button
          as="a"
          size={CtaBoton.Size}
          variant={CtaBoton.Variante}
          className="flex-shrink-0"
        >
          {CtaBoton.Texto}
        </Button>
      </Link>
    </FeaturedText>
  </Container>
);

ComponentRevisadasCtoWithBanner.displayName = 'ComponentRevisadasCtoWithBanner';
