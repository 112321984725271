import { Text } from '@/atoms';

import type { FunctionComponent } from 'react';
import type { TypeTitleProps } from './types';

export const Subtitle: FunctionComponent<TypeTitleProps> = ({
  children,
  className = '',
  as = 'p',
}: TypeTitleProps) => (
  <Text
    as={as}
    className={`md:text-display text-headline text-headline--h4 mb-6 w-full text-neutrals-0 md:w-[590px] ${className}`}
  >
    {children}
  </Text>
);
