import type { FunctionComponent } from 'react';
import type { TypeTextProps } from './types';

export const Description: FunctionComponent<TypeTextProps> = ({
  children,
}: TypeTextProps) => (
  <div className="text-body text-body--l mb-8 text-neutrals-0 md:w-[590px]">
    {children}
  </div>
);
