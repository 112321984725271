import { Container } from '@/atoms';
import { Gallery } from '@/molecules';

import { getRealUrl } from '@/utils/utils';

/**
 * ComponentRevisadasGalleria
 */
export const ComponentRevisadasGalleria = ({
  Imagenes,
  isGrid,
  ConSeccionExtra,
}: any) => (
  <Container
    wrapper
    backgroundColor="lg:bg-primary-50"
    padding="px-4 py-4 md:py-10"
  >
    <Gallery
      images={Imagenes.data.map((itm: any) => ({
        alt: itm?.attributes.alternativeText,
        src: getRealUrl(itm?.attributes.url),
      }))}
      flat={{
        codigoUnidadAlquiler: Imagenes?.attributes?.codigoUnidadAlquiler,
        ventaAlquiler: Imagenes?.attributes?.ventaAlquiler,
        reservable: Imagenes?.attributes?.reservable,
        visitable: Imagenes?.attributes?.visitable,
        nombreUE: Imagenes?.attributes?.nombreUE,
        cityArea: Imagenes?.attributes?.tipoZona?.infoAdicional,
      }}
      isGrid={isGrid}
      typeModal={ConSeccionExtra ? 'form' : 'grid'}
    />
  </Container>
);

ComponentRevisadasGalleria.displayName = 'ComponentRevisadasGalleria';
