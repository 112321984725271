import { useState } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';

import { Button, Card, IconText, Price, Tag } from '@/atoms';
import { ButtonSize, ButtonVariant } from '@/atoms/button/types';
import { IconTextVariant } from '@/atoms/icon-text/types';
import { TagVariant } from '@/atoms/tag/types';

import { getPageName, gtmPush } from '@/utils';

import type { FunctionComponent } from 'react';
import type { TypeCardFlatProps } from './types';

const ModalForm = dynamic(() => import('@/molecules/modal-form'), {
  ssr: true,
});

const FormsTabs = dynamic(() => import('@/organisms/forms-tabs'), {
  ssr: true,
});

/**
 * CardFlat
 */
export const CardFlat: FunctionComponent<TypeCardFlatProps> = ({
  tag,
  title,
  price,
  period,
  description,
  features,
  button,
  image,
  className = '',
  href,
  flat,
}: TypeCardFlatProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <ModalForm isOpen={isOpen} closeModal={() => setIsOpen(false)}>
        <FormsTabs flat={flat} noTitle />
      </ModalForm>

      <Card
        className={{
          card: `flex h-full flex-col bg-bg-neutrals-0 ${className}`,
          content: 'text-body flex h-full flex-col p-4',
          image: 'h-[200px] w-full flex-shrink-0',
        }}
        image={image}
        href={href}
        {...(tag && {
          label: (
            <Tag
              className="absolute right-4 top-4 z-10"
              variant={TagVariant.PROMO}
            >
              {tag}
            </Tag>
          ),
        })}
      >
        <Link
          href={href}
          prefetch={false}
          className="text-body--l mb-1 flex-grow font-bold text-primary-500 line-clamp-2"
        >
          {title}
        </Link>

        <Price price={price} period={period} />

        {description && (
          <p className="text-body--s mt-1">{description.join(' | ')}</p>
        )}

        <div className="tems-start mt-4 mb-6 grid grid-cols-2 grid-rows-3 gap-y-2 xs:grid-cols-3 xs:grid-rows-2">
          {features.slice(0, 6).map((feature, index: number) => (
            <IconText
              Icon={feature.Icon}
              variant={IconTextVariant.SMALL}
              key={index}
              value={feature.value}
            />
          ))}
        </div>

        <Button
          size={ButtonSize.SMALL}
          variant={ButtonVariant.PRIMARY}
          onClick={() => {
            if (flat.ventaAlquiler === 'MED_EST')
              gtmPush({
                event: 'step_lead',
                step: '1-clic_reserva',
                form_name: 'Reservar',
                form_destination: document.URL,
                item_type: 'Media estancia',
                page_type: getPageName(),
                city_area: flat.nombreUE,
              });
            setIsOpen(true);
            button.onClick();
          }}
          className="max-w-max"
        >
          {button.label}
        </Button>
      </Card>
    </>
  );
};

CardFlat.displayName = 'CardFlat';
