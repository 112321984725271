import { Card, Link, Tag, Text } from '@/atoms';
import { LinkVariant } from '@/atoms/link/types';
import { TagVariant } from '@/atoms/tag/types';

import type { FunctionComponent } from 'react';
import type { TypeCardBlogProps } from './types';

/**
 * CardBlog
 */
export const CardBlog: FunctionComponent<TypeCardBlogProps> = ({
  title,
  description,
  image,
  link,
  tag,
  className = '',
}: TypeCardBlogProps) => (
  <Card
    className={{
      card: `bg-bg-neutrals-0 ${className}`,
      content: 'flex flex-col gap-2 px-4 py-6',
      image: 'h-[140px] w-full',
    }}
    image={image}
    href={link.href}
    {...(tag && {
      label: (
        <Tag className="absolute right-4 top-4 z-10" variant={TagVariant.INFO}>
          {tag}
        </Tag>
      ),
    })}
  >
    <Text className="text-headline text-headline--h4 line-clamp-2">
      {title}
    </Text>

    {description && (
      <Text className="text-body text-body--m line-clamp-3 md:line-clamp-2">
        {description}
      </Text>
    )}

    <Link
      href={link.href}
      icon="right"
      variant={LinkVariant.POSITIVE}
      className="pt-4"
    >
      {link.label}
    </Link>
  </Card>
);

CardBlog.displayName = 'CardBlog';
