import { useState } from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { oneLine } from 'common-tags';
import { capitalize } from 'lodash-es';

import ImageIcon from '@/icons/light/image.svg';
import PlayIcon from '@/icons/light/play.svg';
import { BackgroundImage, Button } from '@/atoms';
import { ButtonSize, ButtonVariant } from '@/atoms/button/types';
import { Grid } from '@/molecules';

import FallBackIMG from '@/assets/images/fallback-product-card.jpg';
import { getImageWithFallback, getUrlExtension, isPNG, toArray } from '@/utils';
import { gtmPush } from '@/utils/gtm/helpers';

import type { FunctionComponent } from 'react';
import type { TypeGalleryProps } from './types';

const FormsTabs = dynamic(() => import('@/organisms/forms-tabs'), {
  ssr: true,
});

const ModalGallery = dynamic(() => import('@/atoms/modal-gallery'), {
  ssr: true,
});

const GalleryMobile = dynamic(() => import('@/molecules/gallery-mobile'), {
  ssr: true,
});

const ContactForm = dynamic(() => import('@/molecules/contact-form'), {
  ssr: true,
});

const ReactPlayer = dynamic(() => import('react-player'), {
  ssr: false,
});

const imageWithFallback = {
  src: FallBackIMG.src,
  alt: 'Content image',
};

/**
 * Gallery
 */
export const Gallery: FunctionComponent<TypeGalleryProps> = ({
  className = '',
  images,
  video,
  flat,
  typeModal = 'form',
  isGrid = true,
}: TypeGalleryProps) => {
  const [isGalleryModalOpen, setGalleryModalOpen] = useState(false);
  const openGalleryModal = () => {
    setGalleryModalOpen(true);
    gtmPush({
      event: 'see_all_photos',
      item_id: flat?.codigoUnidadAlquiler,
      item_name: flat?.nombreUE,
      item_type: capitalize(flat?.ventaAlquiler),
    });
  };

  const [isVideoModalOpen, setVideoModalOpen] = useState(false);
  const openVideoModal = () => {
    setVideoModalOpen(true);
    gtmPush({
      event: 'see_video',
      item_id: flat?.codigoUnidadAlquiler,
      item_name: flat?.nombreUE,
      item_type: capitalize(flat?.ventaAlquiler),
    });
  };

  const imagesToShow = toArray(images);
  const srcWithFallback = getImageWithFallback(
    imagesToShow[0],
    imageWithFallback
  );
  const isGallery = imagesToShow.length > 1;
  const canBeGrid = imagesToShow.length >= 3;
  const buttonPhotoModal = (
    <Button
      link
      size={ButtonSize.SMALL}
      variant={ButtonVariant.ELEVATED}
      className="hidden !gap-x-2 lg:flex"
      onClick={openGalleryModal}
    >
      <ImageIcon className="h-4 w-4" /> Ver fotos
    </Button>
  );
  const buttonVideoModal = (
    <Button
      link
      size={ButtonSize.SMALL}
      variant={ButtonVariant.ELEVATED}
      className="!gap-x-2"
      onClick={openVideoModal}
    >
      <PlayIcon className="h-4 w-4" /> Ver video
    </Button>
  );

  return (
    <div className={`h-full ${className}`}>
      {isGrid && canBeGrid ? (
        <Grid className="hidden h-[402px] w-full gap-2 lg:grid" lg={3}>
          <Grid.Item className="col-span-2 row-span-2 cursor-pointer">
            <div
              className="relative h-full w-full [&_img]:rounded-l-lg"
              onClick={openGalleryModal}
            >
              <BackgroundImage backgroundImage={srcWithFallback.src} />
            </div>
          </Grid.Item>

          <Grid.Item className="col-span-2 col-start-3 cursor-pointer">
            <div
              className="relative h-full w-full [&_img]:rounded-tr-lg"
              onClick={openGalleryModal}
            >
              <BackgroundImage
                backgroundImage={
                  getImageWithFallback(imagesToShow[1], imageWithFallback).src
                }
              />
            </div>
          </Grid.Item>

          <Grid.Item className="relative col-span-2 col-start-3 cursor-pointer">
            <div
              className="relative h-full w-full [&_img]:rounded-br-lg"
              onClick={openGalleryModal}
            >
              <BackgroundImage
                backgroundImage={
                  getImageWithFallback(imagesToShow[2], imageWithFallback).src
                }
              />
            </div>
          </Grid.Item>
        </Grid>
      ) : (
        <div
          className={oneLine`
            relative h-[300px] lg:flex
            ${isGallery ? 'hidden' : 'flex'}
          `}
        >
          <Image
            src={srcWithFallback.src}
            alt={srcWithFallback.alt}
            className={oneLine`
              absolute inset-0 h-full w-full rounded-lg object-center
              ${
                isPNG(getUrlExtension(srcWithFallback.src))
                  ? 'object-contain'
                  : 'object-cover'
              }
            `}
            draggable="false"
            sizes="100vw"
            fill
          />

          <div className="absolute bottom-2 right-2 flex flex-row gap-3">
            {video && isGallery ? buttonVideoModal : null}
            {isGallery ? buttonPhotoModal : null}
          </div>
        </div>
      )}

      {isGallery && (
        <>
          <GalleryMobile
            images={imagesToShow}
            perPage={1}
            className="lg:hidden"
            onClick={openGalleryModal}
          />

          <ModalGallery
            closeModal={() => setGalleryModalOpen(false)}
            isOpen={isGalleryModalOpen}
            className="u-wrapper scrollbar-hide h-full overflow-y-auto bg-neutrals-0 lg:rounded-lg"
            isViewForms={typeModal === 'form' || typeModal === 'gallery'}
            flat={flat}
          >
            <div
              className={oneLine`
                flex h-full w-full justify-between gap-6 lg:mb-4
                ${typeModal === 'gallery' ? 'lg:hidden' : ''}
              `}
            >
              <div className="flex w-full flex-col lg:gap-2">
                <Grid className="gap-y-2 md:gap-2" xxs={1} md={2}>
                  {imagesToShow.map((img, index: number) => (
                    <Grid.Item
                      className="md:[&:nth-child(3n_+_1)]:col-span-2"
                      key={index}
                    >
                      <div className="relative h-full min-h-[300px] w-full">
                        <BackgroundImage backgroundImage={img.src} />
                      </div>
                    </Grid.Item>
                  ))}
                </Grid>
              </div>

              {(typeModal === 'form' || typeModal === 'gallery') && (
                <div className="top-16 hidden h-full min-w-[368px] flex-col gap-6 rounded-lg bg-neutrals-50 p-6 lg:sticky lg:flex">
                  <h3 className="md:text-headline md:text-headline--h4">
                    Contacta con nosotros
                  </h3>

                  {flat ? <FormsTabs flat={flat} /> : <ContactForm />}
                </div>
              )}
            </div>

            {typeModal === 'gallery' && (
              <GalleryMobile
                images={imagesToShow}
                perPage={1}
                height=""
                classNameSlide="aspect-video"
                className="mb-4 hidden aspect-video max-w-7xl lg:block"
              />
            )}
          </ModalGallery>

          <ModalGallery
            closeModal={() => setVideoModalOpen(false)}
            isOpen={isVideoModalOpen}
            className="w u-wrapper scrollbar-hide h-full overflow-y-auto bg-neutrals-0 lg:rounded-lg"
            isViewForms={typeModal === 'form' || typeModal === 'gallery'}
            flat={flat}
          >
            <ReactPlayer
              className="w-100 mb-4 h-screen"
              width="100%"
              height="100%"
              controls={true}
              playing={isVideoModalOpen}
              onEnded={() => setVideoModalOpen(false)}
              volume={0.8}
              url={video}
            />
          </ModalGallery>

          {isGrid && canBeGrid && (
            <div className="absolute bottom-2 right-2 flex flex-row gap-3">
              {video ? buttonVideoModal : null}
              {buttonPhotoModal}
            </div>
          )}
        </>
      )}
    </div>
  );
};

Gallery.displayName = 'Gallery';
