import styles from './Prose.module.css';

import type { FunctionComponent } from 'react';
import type { TypeProseProps } from './types';

/**
 * El componente prose da estilos al texto rico que
 * se recibe desde un CMS.
 */
export const Prose: FunctionComponent<TypeProseProps> = ({
  as: HTMLTag = 'div',
  html,
  className = '',
}: TypeProseProps) => (
  <HTMLTag
    className={`prose max-w-full text-inherit ${styles['c-prose']} ${className}`}
    dangerouslySetInnerHTML={{ __html: html }}
  />
);

Prose.displayName = 'Prose';
