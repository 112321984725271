import router from 'next/router';

import { Button, Container } from '@/atoms';
import { ButtonSize, ButtonVariant } from '@/atoms/button/types';

/**
 * ComponentRevisadasOtraDuda
 */
export const ComponentRevisadasOtraDuda = ({
  Titulo,
  Descripcion,
  TextoBoton,
  LinkBoton,
}: any) => (
  <Container wrapper>
    <div className="flex flex-col gap-6 bg-neutrals-50 px-8 py-12">
      <div className="flex flex-col gap-2">
        <h4>{Titulo}</h4>
        <p>{Descripcion}</p>
      </div>

      <div className="w-32">
        <Button
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.SMALL}
          onClick={() => router.push(LinkBoton)}
        >
          {TextoBoton}
        </Button>
      </div>
    </div>
  </Container>
);

ComponentRevisadasOtraDuda.displayName = 'ComponentRevisadasOtraDuda';
