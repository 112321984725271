import Image from 'next/image';
import NextLink from 'next/link';
import { oneLine } from 'common-tags';

import ChevronRight from '@/icons/light/chevron-right.svg';
import { Card, Link, Tag } from '@/atoms';
import { LinkVariant } from '@/atoms/link/types';
import { TagVariant } from '@/atoms/tag/types';

import type { FunctionComponent } from 'react';
import type { TypeCardServiceProps } from './types';

/**
 * CardService
 */
export const CardService: FunctionComponent<TypeCardServiceProps> = ({
  logo,
  title,
  className = '',
  description,
  tag,
  theme = 'dark',
  href,
}: TypeCardServiceProps) => (
  <Card
    className={{
      card: `h-full w-full shadow-light-s
      ${theme === 'dark' ? 'bg-primary-900' : 'bg-neutrals-0'} 
      ${logo && tag ? '' : 'flex flex-col justify-end'}
      ${className}`,
      content: 'flex flex-col gap-5 p-4 md:gap-10',
    }}
    shadow={theme === 'light'}
    rounded="md"
  >
    <div
      className={oneLine`
        flex items-center justify-between gap-6
        ${logo ? '' : '!justify-end'}
      `}
    >
      {logo && (
        <Image
          alt={logo.alt ?? title}
          src={logo.src}
          width={logo.width}
          height={logo.height}
          draggable={false}
        />
      )}

      {tag && (
        <Tag variant={TagVariant.SERVICE}>
          <div className="line-clamp-1">{tag}</div>
        </Tag>
      )}
    </div>

    <div className="flex items-end justify-between gap-4">
      <div
        className={`text-body flex flex-col gap-2 ${
          theme === 'dark' ? 'text-text-neutrals-0' : ''
        }`}
      >
        <p className="text-body--l font-bold">{title}</p>

        {description && <p className="text-body--s">{description}</p>}
      </div>

      {href && (
        <NextLink href={href} passHref prefetch={false} legacyBehavior>
          <Link
            href={href}
            className="h-10 w-10 rounded-full border border-primary-100 bg-neutrals-0 p-2 hover:bg-primary-50"
            variant={LinkVariant.POSITIVE}
          >
            <ChevronRight width={24} />
          </Link>
        </NextLink>
      )}
    </div>
  </Card>
);

CardService.displayName = 'CardService';
