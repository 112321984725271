import Link from 'next/link';

import type { FunctionComponent } from 'react';
import type { TypeTabGroupProps, TypeTabsGroups } from './types';

export const TabGroup: FunctionComponent<TypeTabGroupProps> = ({
  className = '',
  tabsgroups,
}: TypeTabGroupProps) => (
  <div className={`-mx-4 overflow-x-auto md:mx-0 ${className}`}>
    <div className="flex justify-start gap-4 p-4 md:justify-center">
      {tabsgroups?.map((tabGroup: TypeTabsGroups, index: number) => (
        <Link
          href={tabGroup.href}
          prefetch={false}
          key={`${index}_${tabGroup.href}`}
          className="text-button flex-shrink-0 self-center rounded-full border border-solid border-neutrals-100 px-3 py-2 text-neutrals-900 md:px-4 md:py-2"
        >
          {tabGroup.label}
        </Link>
      ))}
    </div>
  </div>
);

TabGroup.displayName = 'TabGroup';
