import { Link } from '@/atoms';

import type { FunctionComponent } from 'react';
import type { TypeProductBlockProps } from './types';

export const ProductBlock: FunctionComponent<TypeProductBlockProps> = ({
  className = '',
  title,
  description,
  link,
  children,
}: TypeProductBlockProps) => (
  <div className={`flex flex-col gap-8 ${className}`}>
    {(title || description) && (
      <div className="flex flex-col items-start gap-2 md:items-center">
        {title && <h2 className="text-headline text-headline--h3">{title}</h2>}
        {description && (
          <span className="text-body text-body--m md:text-body--l">
            {description}
          </span>
        )}
      </div>
    )}

    {children}

    {link && (
      <div className="items-start">
        <Link href={link.href} icon="right">
          {link.label}
        </Link>
      </div>
    )}
  </div>
);

ProductBlock.displayName = 'ProductBlock';
