import { Hero } from './Hero';
import { Button, Description, Image, List, Subtitle, Title } from './childs';

export type TypeHero = typeof Hero & {
  Button: typeof Button;
  Title: typeof Title;
  Description: typeof Description;
  Image: typeof Image;
  List: typeof List;
  Subtitle: typeof Subtitle;
};

(Hero as TypeHero).Button = Button;
(Hero as TypeHero).Title = Title;
(Hero as TypeHero).Description = Description;
(Hero as TypeHero).Image = Image;
(Hero as TypeHero).List = List;
(Hero as TypeHero).Subtitle = Subtitle;

Button.displayName = 'Hero.Button';
Title.displayName = 'Hero.Title';
Description.displayName = 'Hero.Description';
Image.displayName = 'Hero.Image';
List.displayName = 'Hero.List';
Subtitle.displayName = 'Hero.Subtitle';

export type { TypeHeroProps } from './types';
export type {
  TypeTextProps,
  TypeImageComplete,
  TypeImageRightProps,
  TypeListProps,
  TypeTitleProps,
} from './childs/types';

export default Hero as TypeHero;
