import type { TypeChildren, TypeClassName } from '@/types';

export const enum TagVariant {
  INFO = 'INFO',
  PROMO = 'PROMO',
  SERVICE = 'SERVICE',
}

export interface TypeTagProps extends TypeClassName, TypeChildren {
  /**
   * Colores del tag
   */
  variant: TagVariant;
}
