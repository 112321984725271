import { Text } from '@/atoms';

import type { FunctionComponent } from 'react';
import type { TypePriceProps } from './types';

export const Price: FunctionComponent<TypePriceProps> = ({
  className = '',
  price,
  period,
}: TypePriceProps) => (
  <div className={`flex items-center ${className}`}>
    <Text
      suppressHydrationWarning={true}
      className="text-price text-neutrals-900"
    >
      {price}
    </Text>
    {period && (
      <Text className="text-body text-body--l text-neutrals-500">{period}</Text>
    )}
  </div>
);

Price.displayName = 'Price';
