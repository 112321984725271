import { oneLine } from 'common-tags';

import { TagVariant } from './types';

import type { FunctionComponent } from 'react';
import type { TypeTagProps } from './types';

const VARIANT_MAPS: Record<TagVariant, string> = {
  [TagVariant.INFO]: 'border-neutrals-200 bg-neutrals-0 text-neutrals-500',
  [TagVariant.PROMO]: 'border-transparent bg-primary-100 text-primary-500',
  [TagVariant.SERVICE]:
    'border-transparent bg-secondary-500 text-secondary-900',
};

export const Tag: FunctionComponent<TypeTagProps> = ({
  className = '',
  variant,
  children,
}: TypeTagProps) => (
  <span
    className={oneLine`
      text-body text-body--s flex max-w-max items-center gap-1 rounded-full border px-3 py-1.5 font-bold
      ${VARIANT_MAPS[variant]}
      ${className}
    `}
  >
    {children}
  </span>
);

Tag.displayName = 'Tag';
