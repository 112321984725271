import type { FunctionComponent } from 'react';
import type { TypeIconBackgroundProps } from './types';

export const IconBackground: FunctionComponent<TypeIconBackgroundProps> = ({
  className = '',
  Icon,
}: TypeIconBackgroundProps) => (
  <div
    className={`flex h-10 w-10 items-center justify-center rounded-lg bg-primary-50 md:h-16 md:w-16 ${className}`}
  >
    <Icon className="h-6 w-6 md:h-10 md:w-10" />
  </div>
);

IconBackground.displayName = 'IconBackground';
