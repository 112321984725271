import { routes } from '@/lib';
import { capitalize } from '@/utils';

import type { TypeHitZone } from '@/types';
import type { TypeFlat } from '@/types/analytics/flat';

export const flatName = (hit: any) =>
  `${hit?.tipoDeUsoDescription} de ${hit?.habitaciones} ${
    (hit?.habitaciones as number) > 1 ? 'habitaciones' : 'habitación'
  } en ${capitalize(hit?.STREET) ?? ''} (${
    capitalize(hit?.ciudad as string) ?? ''
  })`;

export const convertToURL = (string: string) =>
  string
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .replaceAll('-', ' ')
    .replace(/\s\s+/g, ' ')
    .replaceAll(' ', '-')
    .replaceAll('(', '')
    .replaceAll(')', '');

const zone: { [key in TypeFlat['ventaAlquiler']]: (zone: string) => string } = {
  MED_EST: routes.flats.half.zone,
  ALQUILER: routes.flats.rent.zone,
  VENTA: (zone: string) => `${routes.flats.sale}/${zone}`,
  ALQ_VENT: routes.flats.half.zone,
};

export const zonePath = (hit: TypeHitZone, type: TypeFlat['ventaAlquiler']) =>
  convertToURL(
    zone[type ?? 'MED_EST'](
      `${hit?.provincia ? hit.provincia : ''}${
        hit?.ciudad ? `/${hit.ciudad}` : ''
      }${hit?.zona ? `/${hit.zona}` : ''}`
    )
  );

export const flatPath = (hit: any) =>
  routes.flats.flat(
    `${convertToURL(flatName(hit))}-${hit?.codigoUnidadAlquiler.replaceAll(
      '/',
      '-'
    )}`
  );

export const getLastRefined = (items: any, defaultValue: string): string => {
  const item = items?.filter((item: any) => item.isRefined)[0];

  if (item && item.isRefined) {
    const hasChildrenRefined =
      item?.data?.filter((item: any) => item.isRefined)?.length > 0;

    if (hasChildrenRefined && item?.data) {
      return getLastRefined(item.data, defaultValue);
    } else {
      return item?.value;
    }
  } else {
    return defaultValue;
  }
};

export const getLastRefinedItem = (items: any): any => {
  const item = items?.filter((item: any) => item.isRefined)[0];

  if (item && item.isRefined) {
    const hasChildrenRefined =
      item?.data?.filter((item: any) => item.isRefined)?.length > 0;

    if (hasChildrenRefined && item?.data) {
      return getLastRefinedItem(item.data);
    } else {
      return item;
    }
  } else {
    return null;
  }
};
