import { oneLine } from 'common-tags';

import { Text } from '@/atoms';

import type { FunctionComponent } from 'react';
import type { TypeFeaturedTextProps } from './types';

/**
 * FeaturedText
 */
export const FeaturedText: FunctionComponent<TypeFeaturedTextProps> = ({
  className = '',
  Icon,
  children,
  text,
}: TypeFeaturedTextProps) => (
  <div
    className={oneLine`
      flex flex-row items-center gap-4 rounded-lg bg-primary-50 py-4 pl-2 pr-4 md:justify-center
      ${className}
    `}
  >
    <Icon width={64} height={64} className="flex-shrink-0" />

    <div className="flex flex-col justify-between gap-4 md:w-full md:flex-row md:items-center">
      <Text className="text-body text-body--m font-bold md:text-headline md:text-headline--h4">
        {text}
      </Text>

      {children}
    </div>
  </div>
);

FeaturedText.displayName = 'FeaturedText';
