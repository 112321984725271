import type { TypeClassName } from '@/types';
import type { JSXElementConstructor } from 'react';

export const enum IconTextVariant {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
}

export interface TypeIconTextProps extends TypeClassName {
  /**
   * Icono
   */
  Icon: JSXElementConstructor<any>;
  /**
   * Indica el tipo de fuente a mostra
   */
  variant: IconTextVariant;
  /**
   * Título
   */
  title?: string;
  /**
   * Valor
   */
  value: string;
}
