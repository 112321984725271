import { HitsPerPage } from 'react-instantsearch-hooks-web';
import dynamic from 'next/dynamic';

import MapPin from '@/icons/light/map-pin.svg';
import { Hero, HomeParaguas } from '@/molecules';

import { getRealUrl } from '@/utils/utils';

import type { TypeCard as TypeCardHomeParaguas } from '@/molecules/home-paraguas/types';

const InstantSearch = dynamic(
  () => import('@/components/algolia/instant-search'),
  {
    ssr: true,
  }
);

const SearchBoxDesktop = dynamic(
  () => import('@/components/algolia/search-box-desktop'),
  {
    ssr: true,
  }
);

/**
 * ComponentRevisadasHero
 */
export const ComponentRevisadasHero = ({
  Titulo,
  Subtitulo,
  Descripcion,
  ImagenFondo,
  videoFondo,
  videoFondoMovil,
  ListItem,
  InstantSearchSettings,
  ColorFondo,
  Boton,
  Overlay,
  Tipo,
  CardLinks,
  TituloCards,
  DescripcionCards,
}: any) => (
  <div className={InstantSearchSettings ? 'relative' : 'overflow-hidden'}>
    <Hero
      backgroundImage={
        ImagenFondo?.data?.attributes.url
          ? getRealUrl(ImagenFondo.data.attributes.url)
          : undefined
      }
      backgroundVideo={{
        desktop: videoFondo?.data?.map((itm: any) => ({
          src: getRealUrl(itm?.attributes?.url),
          type: itm?.attributes?.mime,
        })),
        mobile: videoFondoMovil?.data?.map((itm: any) => ({
          src: getRealUrl(itm?.attributes?.url),
          type: itm?.attributes?.mime,
        })),
      }}
      backgroundColor={ColorFondo == 'Negro' ? 'bg-primary-900' : undefined}
      className={
        Tipo == 'Home'
          ? `${
              InstantSearchSettings ? 'mb-[130px] md:mb-[120px]' : ''
            } h-[370px]  md:h-[500px]`
          : Tipo?.includes('Landing')
          ? 'h-[445px] md:h-[580px]'
          : undefined
      }
      justify={
        Tipo == 'Home'
          ? 'md:justify-center'
          : Tipo?.includes('Landing')
          ? 'justify-center'
          : 'lg:justify-center'
      }
      variant={
        Tipo?.includes('Landing')
          ? 'default'
          : Tipo == 'Home'
          ? undefined
          : 'blog'
      }
      notLazy
      overlay={Overlay}
      align={
        Tipo == 'LandingDerecha'
          ? 'items-end'
          : Tipo == 'LandingIzquierda'
          ? 'items-start'
          : undefined
      }
      textAlign={
        Tipo == 'LandingDerecha'
          ? 'text-end'
          : Tipo == 'LandingIzquierda'
          ? 'text-start'
          : undefined
      }
    >
      <Hero.Title as="h1" className="mt-6 md:mt-0">
        {Titulo}
      </Hero.Title>
      {Subtitulo && (
        <Hero.Subtitle
          as="h2"
          className={
            Tipo == 'LandingDerecha'
              ? '!mb-0 flex justify-end gap-2'
              : Tipo == 'LandingIzquierda'
              ? 'flex gap-2'
              : undefined
          }
        >
          {Subtitulo}
          <MapPin width={30} />
        </Hero.Subtitle>
      )}
      {Descripcion && <Hero.Description>{Descripcion}</Hero.Description>}
      {Boton?.[0] && (
        <Hero.Button
          href={Boton?.[0].Link || '#'}
          size={Boton?.[0].Size}
          variant={Boton?.[0].Variante}
        >
          {Boton?.[0].Texto}
        </Hero.Button>
      )}
      {ListItem.length !== 0 && (
        <Hero.List items={ListItem.map((itm: any) => itm.Item)} />
      )}
    </Hero>

    {InstantSearchSettings ? (
      <InstantSearch
        indexName={`development_api::zfm-disponiblidad.${InstantSearchSettings.Indice.replaceAll(
          '_',
          '-'
        )}`}
      >
        <SearchBoxDesktop
          placeholder={InstantSearchSettings.TextoBuscador}
          className="!absolute top-full left-0 right-0 z-10 -translate-y-1/2"
          typeFlat={InstantSearchSettings.TipoVivienda}
        />

        <HitsPerPage
          items={[
            {
              label: '',
              value: InstantSearchSettings.NumeroResultados,
              default: true,
            },
          ]}
          className="hidden"
        />
      </InstantSearch>
    ) : null}

    {InstantSearchSettings === null && CardLinks && CardLinks.length > 0 ? (
      <div className="w-full">
        <HomeParaguas
          className="u-wrapper lg:relative lg:-top-11 lg:z-50"
          title={TituloCards}
          desc={DescripcionCards}
          cards={
            CardLinks?.map((card: any) => ({
              Icon: dynamic(
                () =>
                  import(
                    `@/icons/duotone/${card.Icono.replaceAll('_', '-')}.svg`
                  )
              ),
              title: card.Titulo,
              desc: card.Descripcion,
              link: {
                href: card.UrlLink,
                label: card.TituloLink,
              },
              theme: card.Tema,
            })) as TypeCardHomeParaguas[]
          }
        />
      </div>
    ) : null}
  </div>
);

ComponentRevisadasHero.displayName = 'ComponentRevisadasHero';
