import dynamic from 'next/dynamic';

import { BackgroundImage } from '@/atoms';
import { TwoColumn } from '@/molecules';

import { getRealUrl } from '@/utils/utils';

const ContactForm = dynamic(() => import('@/molecules/contact-form'), {
  ssr: true,
});

/**
 * ComponentRevisadasContactForm
 */
export const ComponentRevisadasContactForm = ({
  Titulo,
  Descripcion,
  Image,
}: any) => (
  <TwoColumn className="h-full w-full md:min-h-[500px]">
    <TwoColumn.Left className="relative min-h-[180px] w-full md:h-full">
      {Image?.data?.attributes?.url && (
        <BackgroundImage
          backgroundImage={getRealUrl(Image.data.attributes.url)}
        />
      )}
    </TwoColumn.Left>

    <TwoColumn.Right className="h-full w-full">
      <div className="m-4 flex h-full flex-col gap-6 bg-bg-neutrals-0 md:m-7 lg:m-14 lg:max-w-[calc(var(--wrapper-size)/2)] xl:w-[548px]">
        <h1>{Titulo}</h1>
        <div dangerouslySetInnerHTML={{ __html: Descripcion }} />

        <ContactForm />
      </div>
    </TwoColumn.Right>
  </TwoColumn>
);

ComponentRevisadasContactForm.displayName = 'ComponentRevisadasContactForm';
