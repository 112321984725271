import { Container } from '@/atoms';
import { CardService, ProductBlock, SliderCard } from '@/molecules';

import { getRealUrl } from '@/utils/utils';

/**
 * ComponentRevisadasServicios
 */
export const ComponentRevisadasServicios = ({
  Titulo,
  Descripcion,
  servicios,
  Ancla,
  FondoAzul,
}: any) =>
  servicios?.data?.length > 0 ? (
    <Container
      wrapper
      id={Ancla}
      backgroundColor={FondoAzul ? 'bg-primary-50' : undefined}
    >
      <ProductBlock title={Titulo} description={Descripcion}>
        <SliderCard
          perPage={3}
          breakpoints={{
            1024: {
              perPage: 3,
            },
            768: {
              padding: 38,
              perPage: 2,
              gap: 16,
            },
            500: {
              padding: 30,
              perPage: 1,
            },
          }}
        >
          {servicios.data?.map((itm: any, index: number) => (
            <CardService
              key={`${itm.__typename}-${index}`}
              logo={
                itm.attributes.Logo?.data && {
                  ...itm.attributes.Logo?.data?.attributes,
                  alt: itm.attributes.Logo?.data?.attributes?.alternativeText,
                  src: getRealUrl(itm.attributes.Logo?.data?.attributes?.url),
                }
              }
              title={itm.attributes.Titulo}
              description={itm.attributes.Descripcion}
              tag={itm.attributes.Tag}
            />
          ))}
        </SliderCard>
      </ProductBlock>
    </Container>
  ) : null;

ComponentRevisadasServicios.displayName = 'ComponentRevisadasServicios';
