import { Container } from '@/atoms';

import LogoMobile from '@/assets/images/nestar-logo-blog-mobile.svg';
import Logo from '@/assets/images/nestar-logo-blog.svg';

import type { FunctionComponent } from 'react';
import type { TypeHeroProps } from './types';

export const Hero: FunctionComponent<TypeHeroProps> = ({
  className = '',
  classNameWrapper = '',
  backgroundImage,
  backgroundTop,
  backgroundColor,
  backgroundVideo,
  children,
  overlay,
  justify,
  textAlign,
  align,
  minHeight,
  backgroundBottom,
  notLazy = true,
  style,
  variant = 'default',
}: TypeHeroProps) => (
  <div className="relative">
    <Container
      wrapper
      className={`
        ${variant === 'blog' ? 'min-h-[385px] md:h-[450px] lg:h-[400px]' : ''}
        ${className}
      `}
      classNameWrapper={classNameWrapper}
      backgroundImage={backgroundImage}
      backgroundColor={backgroundColor}
      backgroundVideo={backgroundVideo}
      justify={justify}
      align={align}
      textAlign={textAlign}
      overlay={overlay}
      backgroundTop={backgroundTop}
      backgroundBottom={backgroundBottom}
      backgroundLazy={notLazy}
      style={{
        ...style,
        ...(minHeight && {
          minHeight: `${minHeight}px`,
        }),
      }}
    >
      {children}
    </Container>

    {variant === 'blog' && (
      <>
        <Logo className="absolute left-1/2 top-0 bottom-0 hidden lg:block 2xl:right-0 2xl:left-[unset]" />
        <LogoMobile className="absolute bottom-0 right-0 left-0 lg:hidden" />
      </>
    )}
  </div>
);

Hero.displayName = 'Hero';
