import { Container } from '@/atoms';
import { CardZone, ProductBlock, SliderCard } from '@/molecules';

import { getRealUrl } from '@/utils/utils';

/**
 * ComponentRevisadasOtrasZonas
 */
export const ComponentRevisadasOtrasZonas = ({
  Titulo,
  Descripcion,
  zonas,
  Enlace,
}: any) =>
  zonas?.data?.length > 0 ? (
    <Container wrapper>
      <ProductBlock title={Titulo} description={Descripcion}>
        <SliderCard
          link={{ label: 'Ver todas', href: Enlace }}
          perPage={3}
          breakpoints={{
            1024: {
              perPage: 3,
            },
            768: {
              padding: 38,
              perPage: 2,
              gap: 16,
            },
            500: {
              padding: 30,
              perPage: 1,
            },
          }}
        >
          {zonas.data.map((itm: any, index: number) => (
            <CardZone
              key={`otras-zonas-${index}`}
              title={`En ${itm.attributes.Nombre}`}
              image={{
                src: getRealUrl(itm.attributes.Imagen.data.attributes.url),
                alt: itm.attributes.Imagen.data.attributes.alternativeText,
              }}
              href={itm.attributes.Link}
              className="text-neutrals-900"
            />
          ))}
        </SliderCard>
      </ProductBlock>
    </Container>
  ) : null;

ComponentRevisadasOtrasZonas.displayName = 'ComponentRevisadasOtrasZonas';
