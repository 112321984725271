import Link from 'next/link';

import { Button as ButtonLink } from '@/atoms';

import type { FunctionComponent } from 'react';
import type { TypeButtonProps } from './types';

/**
 * Button
 */
export const Button: FunctionComponent<TypeButtonProps> = ({
  children,
  outline,
  size,
  variant,
  href,
  className = '',
}: TypeButtonProps) => (
  <Link href={href} prefetch={false} className={className}>
    <ButtonLink outline={outline} size={size} variant={variant} as="span">
      {children}
    </ButtonLink>
  </Link>
);

Button.displayName = 'Button';
