import { useState } from 'react';
import dynamic from 'next/dynamic';

import { Container } from '@/atoms';
import { ContentImage } from '@/molecules';

import { getRealUrl } from '@/utils/utils';

const ModalContact = dynamic(() => import('@/molecules/modal-form'), {
  ssr: true,
});

const ContactForm = dynamic(() => import('@/molecules/contact-form'), {
  ssr: true,
});

/**
 * ComponentRevisadasContenidoConImagen
 */
export const ComponentRevisadasContenidoConImagen = ({
  Titulo,
  Descripcion,
  Imagen,
  Ancla,
  Inverso,
  TipografiaSize,
  Boton,
  BotonTipo,
}: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const hasContactForm = BotonTipo === 'Contacto';

  return (
    <>
      {hasContactForm && (
        <ModalContact closeModal={() => setIsOpen(false)} isOpen={!!isOpen}>
          <ContactForm />
        </ModalContact>
      )}

      <Container wrapper classNameWrapper="py-6" id={Ancla}>
        <ContentImage
          description={Descripcion}
          font={TipografiaSize}
          image={Imagen.data.map((img: any) => ({
            alt: img?.attributes?.alternativeText,
            src: getRealUrl(img?.attributes.url),
          }))}
          title={Titulo}
          reverse={Inverso}
          isViewForms={hasContactForm}
          {...(hasContactForm && {
            button: {
              label: Boton?.[0].Texto,
              onClick: () =>
                setIsOpen(
                  Boton?.[0]?.zfm_disponiblidad?.data?.attributes?.codigoUE
                ),
            },
          })}
          {...(BotonTipo === 'Link' && {
            link: {
              href: Boton?.[0]?.Link || '#',
              type: 'button',
              label: Boton?.[0]?.Texto,
            },
          })}
        />
      </Container>
    </>
  );
};

ComponentRevisadasContenidoConImagen.displayName =
  'ComponentRevisadasContenidoConImagen';
