import { Feature, Grid, ProductBlock } from '@/molecules';

import type { FunctionComponent } from 'react';
import type { TypeBenefitsProps } from './types';

/**
 * Benefits
 */
export const Benefits: FunctionComponent<TypeBenefitsProps> = ({
  className = '',
  title,
  description,
  benefits,
}: TypeBenefitsProps) => (
  <ProductBlock title={title} description={description} className={className}>
    <Grid xxs={1} sm={2} lg={4} className="gap-6">
      {benefits.map((feature, index: number) => (
        <Grid.Item key={index}>
          <Feature Icon={feature.Icon} title={feature.title}>
            {feature.description}
          </Feature>
        </Grid.Item>
      ))}
    </Grid>
  </ProductBlock>
);

Benefits.displayName = 'Benefits';
