import { useHits } from 'react-instantsearch-hooks-web';

import { Container } from '@/atoms';
import { CardFlat, ProductBlock, SliderCard } from '@/molecules';

import { cardTransformer } from '@/graphql/transformers';
import { debug } from '@/lib';

const removeCurrentFlat = (propertyId: string, flat: any) =>
  (propertyId && flat.codigoUnidadAlquiler !== propertyId) || !propertyId;

/**
 * ComponentRevisadasViviendasMasBuscadas
 */
export const ComponentRevisadasViviendasMasBuscadas = ({
  Titulo,
  Descripcion,
  AzulFondo,
  PaddingVertical,
  zfm_disponiblidads,
  propertyId,
  VerMasText,
  VerMasLink,
}: any) => {
  let hits: any[] = [];

  try {
    const searchContext = useHits();

    if (searchContext) {
      hits = searchContext.hits;
    }
  } catch (err) {
    debug('no context found');
  }

  const flats = hits.length > 0 ? hits : zfm_disponiblidads?.data;
  const validFlats =
    flats?.filter((ele: any) => removeCurrentFlat(propertyId, ele)) ?? [];

  return validFlats.length > 0 ? (
    <Container
      wrapper
      backgroundColor={AzulFondo ? 'bg-primary-50' : undefined}
      classNameWrapper={PaddingVertical ? 'py-6' : undefined}
    >
      <ProductBlock title={Titulo} description={Descripcion}>
        <SliderCard
          perPage={3}
          breakpoints={{
            1024: {
              perPage: 3,
            },
            768: {
              padding: 38,
              perPage: 2,
              gap: 16,
            },
            500: {
              padding: 30,
              perPage: 1,
            },
          }}
          {...(VerMasText &&
            VerMasLink && {
              link: {
                label: VerMasText,
                href: VerMasLink,
              },
            })}
        >
          {validFlats
            .map((ele: any, index: number) => (
              <CardFlat
                key={index}
                {...cardTransformer(
                  ele.attributes || ele,
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  () => {}
                )}
              />
            ))
            .filter(Boolean)}
        </SliderCard>
      </ProductBlock>
    </Container>
  ) : null;
};

ComponentRevisadasViviendasMasBuscadas.displayName =
  'ComponentRevisadasViviendasMasBuscadas';
