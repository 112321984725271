import { oneLine } from 'common-tags';

import { Link } from '@/atoms';
import { LinkVariant } from '@/atoms/link/types';

import NestarLogo from '@/assets/images/search-logo.svg';
import ArrowRight from '@/components/icons/light/arrow-right.svg';

import type { FunctionComponent } from 'react';
import type { TypeHomeParaguasProps } from './types';

/**
 * HomeParaguas
 */
export const HomeParaguas: FunctionComponent<TypeHomeParaguasProps> = ({
  className = '',
  desc,
  title,
  cards,
}: TypeHomeParaguasProps) => (
  <div
    className={`flex h-fit w-full flex-col gap-2  bg-primary-500 p-6 text-neutrals-0 lg:rounded-lg ${className}`}
  >
    <NestarLogo className="u-wrapper hidden h-full md:absolute md:top-0 md:bottom-0 md:right-0 md:block" />

    <div className="relative z-10">
      <h2 className="text-headline--h3">{title}</h2>
      <span className="text-body--l">{desc}</span>

      <div className="mt-4 flex flex-col gap-6 lg:flex-row">
        {cards.map((card, index) => (
          <div
            className={oneLine`
              flex flex-col gap-6 rounded-lg p-8 text-neutrals-900
              ${
                card?.theme && card.theme === 'dark'
                  ? 'bg-primary-900 text-neutrals-0'
                  : 'bg-neutrals-0 text-neutrals-900'
              }
            `}
            key={index}
          >
            <card.Icon height={50} width={50} />

            <h4 className="text-headline--h4">{card.title}</h4>
            <span className="text-body--l">{card.desc}</span>

            <Link
              className="items-center"
              variant={LinkVariant.POSITIVE}
              href={card.link.href}
            >
              {card.link.label}
              <ArrowRight height={13} width={13} />
            </Link>
          </div>
        ))}
      </div>
    </div>
  </div>
);

HomeParaguas.displayName = 'HomeParaguas';
