import { Fragment } from 'react';
import dynamic from 'next/dynamic';

import { Container } from '@/atoms';
import { CardService, CardsTabs, Grid } from '@/molecules';

import { ComponentRevisadasBeneficios } from './ComponentRevisadasBeneficios';

/**
 * ComponentRevisadasServiciosTabs
 */
export const ComponentRevisadasServiciosTabs = ({ ServiciosTab }: any) => {
  const tabs = ServiciosTab.map((child: any) => ({
    titulo: child.TituloTab,
    hash: child.HashTab,
    icono: dynamic(
      () =>
        import(
          `/components/icons/duotone/${child.IconoTab.replaceAll('_', '-')}.svg`
        )
    ),
  }));

  return (
    <Container wrapper>
      <CardsTabs tabs={tabs}>
        {ServiciosTab.map((contenidoTab: any, key: number) => (
          <Fragment key={key}>
            <div className="mb-12 flex flex-col gap-6 lg:mb-28 lg:gap-10">
              <h3 className="text-headline-h3">
                {contenidoTab.TituloServicios}
              </h3>

              <Grid className="gap-5" xs={1} md={2}>
                {contenidoTab.servicios.data.map(
                  (serviciosCards: any, indice: any) => (
                    <Grid.Item key={indice}>
                      <CardService
                        logo={
                          serviciosCards.attributes.Logo.data?.attributes
                            ? {
                                alt: serviciosCards.attributes.Logo.data
                                  ?.attributes.alternativeText,
                                src: serviciosCards.attributes.Logo.data
                                  ?.attributes.url,
                                width:
                                  serviciosCards.attributes.Logo.data
                                    ?.attributes.width,
                                height:
                                  serviciosCards.attributes.Logo.data
                                    ?.attributes.height,
                              }
                            : undefined
                        }
                        title={serviciosCards.attributes?.Titulo}
                        description={serviciosCards.attributes?.Descripcion}
                        tag={serviciosCards.attributes?.Tag}
                        theme="light"
                      />
                    </Grid.Item>
                  )
                )}
              </Grid>
            </div>

            <ComponentRevisadasBeneficios
              Descripcion={contenidoTab?.DescripcionBeneficios}
              beneficios={contenidoTab.beneficios}
              Titulo={contenidoTab?.TituloBeneficios}
            />
          </Fragment>
        ))}
      </CardsTabs>
    </Container>
  );
};

ComponentRevisadasServiciosTabs.displayName = 'ComponentRevisadasServiciosTabs';
