import { useEffect, useState } from 'react';
import { Tab } from '@headlessui/react';
import { oneLine } from 'common-tags';

import { IconBackground } from '@/atoms';

import { toArray } from '@/utils';

import type { FunctionComponent } from 'react';
import type { TypeCardsTabsProps } from './types';

/**
 * CardsTabs
 */

export const CardsTabs: FunctionComponent<TypeCardsTabsProps> = ({
  children,
  tabs,
}: TypeCardsTabsProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    const selectedTabHash = window.location.hash.replace(/#/, '');
    const index = tabs.findIndex((t) => selectedTabHash === t.hash);

    if (index !== -1) setSelectedIndex(index);
  }, []);

  return (
    <Tab.Group
      selectedIndex={selectedIndex}
      onChange={(index) => {
        setSelectedIndex(index);
        window.location.hash = tabs[index].hash;
      }}
    >
      <Tab.List className="flex flex-row gap-6 overflow-x-auto">
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            className="focus:ring-0 focus:ring-offset-0 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
          >
            {({ selected }) => (
              <div
                className={oneLine`
                  flex h-full w-[255px] flex-row items-center justify-between gap-4 rounded-lg px-6 py-3 transition-all duration-200 md:w-80 lg:p-6
                  ${
                    selected
                      ? 'border border-primary-500 bg-primary-50 text-primary-500'
                      : 'border border-neutrals-100'
                  }
                `}
              >
                <span className="text-body--l text-start font-bold">
                  {tab.titulo}
                </span>

                <IconBackground
                  Icon={tab.icono}
                  className="!h-10 !w-10 flex-shrink-0"
                />
              </div>
            )}
          </Tab>
        ))}
      </Tab.List>

      <Tab.Panels className="mt-14 lg:mt-16">
        {toArray(children).map((child, index) => (
          <Tab.Panel unmount={false} key={index}>
            {child}
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
};

CardsTabs.displayName = 'CardsTabs';
