import { Container } from '@/atoms';
import { Prose } from '@/molecules';

/**
 * ComponentRevisadasViviendasMasBuscadas
 */
export const ComponentRevisadasLegal = ({
  Titulo,
  Content,
  TablaCookies,
}: any) => (
  <Container wrapper wrapperSize="max-w-[790px]">
    <h1 className="mb-6">{Titulo}</h1>

    <Prose
      className="[&_p]:text-body [&_p]:text-body--l"
      html={`${Content} ${
        TablaCookies ? '<div id="ot-sdk-cookie-policy"></div> ' : ''
      }`}
    />
  </Container>
);

ComponentRevisadasLegal.displayName = 'ComponentRevisadasLegal';
