import dynamic from 'next/dynamic';

import { Container } from '@/atoms';
import { Benefits } from '@/templates';

/**
 * ComponentRevisadasBeneficios
 */
export const ComponentRevisadasBeneficios = ({
  Titulo,
  Descripcion,
  MargenAbajo,
  beneficios,
  Ancla,
}: any) =>
  beneficios?.data.length ? (
    <Container wrapper className={MargenAbajo ? 'mb-12' : ''} id={Ancla}>
      <Benefits
        title={Titulo}
        description={Descripcion}
        benefits={beneficios.data.map((itm: any) => ({
          Icon: dynamic(
            () =>
              import(
                `@/icons/duotone/${itm.attributes.Icon.replaceAll(
                  '_',
                  '-'
                )}.svg`
              )
          ),
          description: itm.attributes.Descripcion,
          title: itm.attributes.Titulo,
        }))}
      />
    </Container>
  ) : null;

ComponentRevisadasBeneficios.displayName = 'ComponentRevisadasBeneficios';
