import { IconBackground } from '@/atoms';

import type { FunctionComponent } from 'react';
import type { TypeFeatureProps } from './types';

export const Feature: FunctionComponent<TypeFeatureProps> = ({
  className = '',
  Icon,
  title,
  children,
}: TypeFeatureProps) => (
  <div
    className={`flex gap-4 lg:flex-col lg:items-center lg:gap-6 ${className}`}
  >
    {Icon && <IconBackground Icon={Icon} className="flex-shrink-0" />}

    {(title || children) && (
      <div className="text-body flex flex-col gap-1 lg:items-center lg:gap-2">
        {title && (
          <span className="text-body--l font-bold lg:text-center">{title}</span>
        )}

        {children && (
          <span className="text-body--m text-text-neutrals-500 lg:text-center">
            {children}
          </span>
        )}
      </div>
    )}
  </div>
);

Feature.displayName = 'Feature';
