import { Container } from '@/atoms';
import { CardService, Grid } from '@/molecules';

/**
 * ComponentRevisadasServiciosList
 */
export const ComponentRevisadasServiciosList = ({ servicios }: any) =>
  servicios.data.length > 0 ? (
    <Container wrapper className="mt-6">
      <Grid md={2} className="flex flex-col justify-center gap-4">
        {servicios.data.map((itm: any, index: number) => (
          <Grid.Item key={`ServicioEntity-${index}`}>
            <CardService
              logo={
                itm.attributes.Logo.data?.attributes
                  ? {
                      alt: itm.attributes.Logo.data?.attributes.alternativeText,
                      src: itm.attributes.Logo.data?.attributes.url,
                      width: itm.attributes.Logo.data?.attributes.width,
                      height: itm.attributes.Logo.data?.attributes.height,
                    }
                  : undefined
              }
              title={itm.attributes.Titulo}
              description={itm.attributes.Descripcion}
              tag={itm.attributes?.Tag}
              theme="light"
            />
          </Grid.Item>
        ))}
      </Grid>
    </Container>
  ) : null;

ComponentRevisadasServiciosList.displayName = 'ComponentRevisadasServiciosList';
