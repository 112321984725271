import { Accordion, Container } from '@/atoms';

/**
 * ComponentRevisadasPreguntasFrequentes
 */
export const ComponentRevisadasPreguntasFrequentes = ({
  pregunta_frecuentes,
  Titulo,
}: any) =>
  pregunta_frecuentes?.data?.length > 0 ? (
    <Container wrapper>
      <h1 className="mb-6">{Titulo}</h1>

      <div className="flex flex-col gap-6">
        {pregunta_frecuentes.data.map((itm: any, index: number) => (
          <Accordion
            key={`faq-${index}`}
            title={itm.attributes.Pregunta}
            variant="normal"
            color="white"
          >
            {itm.attributes.Respuesta}
          </Accordion>
        ))}
      </div>
    </Container>
  ) : null;

ComponentRevisadasPreguntasFrequentes.displayName =
  'ComponentRevisadasPreguntasFrequentes';
