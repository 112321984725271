import TickCircle from '@/icons/duotone/tick-circle-light.svg';

import type { FunctionComponent } from 'react';
import type { TypeListProps } from './types';

/**
 * List
 */
export const List: FunctionComponent<TypeListProps> = ({
  className = '',
  items,
}: TypeListProps) => (
  <div
    className={`text-body text-body--l mb-8 flex flex-col gap-3 text-neutrals-0 md:w-[590px] ${className}`}
  >
    {items.map((item, index: number) => (
      <div className="flex items-center gap-3" key={index}>
        <TickCircle width={16} height={16} />
        <span>{item}</span>
      </div>
    ))}
  </div>
);

List.displayName = 'List';
