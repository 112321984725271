import { Container, Text } from '@/atoms';
import { CardZone, Grid } from '@/molecules';

import { getRealUrl } from '@/utils/utils';

/**
 * ComponentRevisadasZonsasDistributiva
 */
export const ComponentRevisadasZonsasDistributiva = ({
  Titulo,
  descripcion,
  zonas,
}: any) => (
  <Container wrapper>
    <div className="mb-6 flex flex-col items-center justify-center gap-2">
      <Text className="text-headline text-headline--h3">{Titulo}</Text>
      <Text className="text-body text-body--l">{descripcion}</Text>
    </div>

    <Grid xxs={1} sm={2} lg={3} className="gap-6">
      {zonas.data.map((itm: any, index: number) => (
        <Grid.Item key={`zonas-${index}`}>
          <CardZone
            title={`En ${itm.attributes.Nombre}`}
            image={{
              src: getRealUrl(itm.attributes.Imagen.data.attributes.url),
              alt: itm.attributes.Imagen.data.attributes.alternativeText,
            }}
            href={itm.attributes.slug}
            className="text-neutrals-900"
          />
        </Grid.Item>
      ))}
    </Grid>
  </Container>
);

ComponentRevisadasZonsasDistributiva.displayName =
  'ComponentRevisadasZonsasDistributiva';
