import * as Components from '@/components/dynamic-components';

import type { FunctionComponent } from 'react';
import type { TypeDynamicComponentsProps } from './types';

/**
 * DynamicComponents
 */
export const DynamicComponents: FunctionComponent<
  TypeDynamicComponentsProps
> = ({ className = '', type, ...props }: TypeDynamicComponentsProps) => {
  const Component = Components[type as keyof typeof Components] ?? null;

  return Component ? (
    <Component {...props} className={className} componentType={type} />
  ) : (
    Component
  );
};

DynamicComponents.displayName = 'DynamicComponents';
