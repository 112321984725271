import { Container } from '@/atoms';
import { CardBlog, ProductBlock, SliderCard } from '@/molecules';

import { config } from '@/config';
import { routes } from '@/lib';
import { getImageWithFallBackWithOutAlt, getRealUrl } from '@/utils';

/**
 * ComponentRevisadasBlogDestacados
 */
export const ComponentRevisadasBlogDestacados = ({
  Titulo,
  Descripcion,
  blog_posts,
}: any) =>
  blog_posts?.data?.length ? (
    <Container wrapper backgroundColor="bg-primary-50">
      <ProductBlock title={Titulo} description={Descripcion}>
        <SliderCard
          link={{ label: 'Ver todas', href: routes.blog.index }}
          perPage={3}
          breakpoints={{
            1024: {
              perPage: 3,
            },
            768: {
              padding: 38,
              perPage: 2,
              gap: 16,
            },
            500: {
              padding: 30,
              perPage: 1,
            },
          }}
        >
          {blog_posts.data.map((itm: any, index: number) => (
            <CardBlog
              key={`card-blog-${index}`}
              image={{
                alt:
                  itm.attributes?.post?.ImagenPrincipal.data?.attributes
                    ?.alternativeText || itm.attributes.Titulo,
                src: getImageWithFallBackWithOutAlt(
                  getRealUrl(
                    itm.attributes?.post?.ImagenPrincipal.data?.attributes.url
                  ),
                  config.fallbackImage
                ),
              }}
              title={itm.attributes.Titulo}
              description={itm.attributes.Descripcion}
              tag={itm.attributes.post.category.category}
              link={{
                label: 'Saber más',
                href: itm.attributes.slug,
              }}
              className="h-full"
            />
          ))}
        </SliderCard>
      </ProductBlock>
    </Container>
  ) : null;

ComponentRevisadasBlogDestacados.displayName =
  'ComponentRevisadasBlogDestacados';
